import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import { AppFunctionComponent } from "../types"
import NotFoundPageContent from "../ui/components/not-found-page/not-found-page.component"
import { NotFoundProps } from "./pl/404"

const NotFoundPage: AppFunctionComponent<NotFoundProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => (
  <Layout
    promobarContent={promobarContent}
    topNavigationItems={topNavigationItems}
    footerNavigationItems={footerNavigationItems}
  >
    <SEO title="404: Not found" />
    <NotFoundPageContent />
  </Layout>
)

export const query = graphql`
  query NotFoundQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: "en-US" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default NotFoundPage
